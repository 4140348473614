<div class="modal fade" id="modalStorePair" tabindex="-1" aria-labelledby="modalStorePairLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-header text-dark">
                <h3 class="text-center">Añadir nuevo Pair</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeModalBtn (click)="reloadForm()"></button>
            </div>

            <div class="modal-body">
                <form [formGroup]="form" (ngSubmit)="storePair(form.value)">


                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="price" placeholder="Price" mask="separetor.0" thousandSeparator="." autocomplete="off">
                        <label class="text-dark" for="contractAddress">Price</label>
                    </div>


                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="tokenA" placeholder="Token A Address" autocomplete="off">
                        <label class="text-dark" for="contractAddress">Token A Address</label>
                    </div>


                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="tokenB" placeholder="Token B Address" autocomplete="off">
                        <label class="text-dark" for="contractAddress">Token B Address</label>
                    </div>
                

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="amountForTokens" placeholder="Amount for token" mask="separetor.0" thousandSeparator="." autocomplete="off">
                        <label class="text-dark" for="contractAddress">Amount for token</label>
                    </div>
                

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="fee" placeholder="Fee" mask="separetor.0" thousandSeparator="." autocomplete="off">
                        <label class="text-dark" for="contractAddress">Fee</label>
                    </div>
                

                    <div class="form-floating mb-3">
                        <select class="form-select" formControlName="activeOracle" aria-label="Select Oracle Status">
                            <option [value]="true">Enable</option>
                            <option [value]="false">Disable</option>
                        </select>
                        <label class="text-dark" for="activeOracle">Active Oracle</label>
                    </div>
                

                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="addressOracle" placeholder="Address Oracle" autocomplete="off">
                        <label class="text-dark" for="contractAddress">Address Oracle</label>
                    </div>
                

                    <div class="form-floating mb-3">
                        <select class="form-select" formControlName="isNative" aria-label="Token Native">
                            <option [value]="true">Yes</option>
                            <option [value]="false">No</option>
                        </select>
                        <label class="text-dark" for="isNative">Is Native</label>
                    </div>
                
                
                    <div class="mb-3">
                        <button class="btn btn-sm btn-primary" type="submit" [disabled]="!this.form.valid">
                            Store
                        </button>
                    </div>
                
                </form>
            </div>
        </div>
    </div>
</div>

