<app-nav-bar></app-nav-bar>


<div class="container container-custom mt-5 fc-light">
    <div class="row mb-5">
        <div class="col-12 col-sm-10 offset-sm-1 offset-lg-0 mb-3 mb-lg-0 col-lg-5">
            <div class="card card-custom card-collection card-nft">
                <!-- <div class="header-card-nft">
                    <div class="d-flex justify-content-between p-3">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-coin" viewBox="0 0 16 16">
                                <path
                                    d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path
                                    d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                            </svg>
                        </div>
                        <div class="icon">
                            <span>2</span> <i class="bi bi-heart-fill"></i>
                        </div>
                    </div>
                </div> -->
                <div class="img-nft">
                    <img [src]="tokenData?.metadata_json?.image" alt="">
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-10 offset-sm-1 offset-lg-0 col-lg-7">
            <div class="small">
                <span class="fc-primary">{{tokenData?.metadata_json?.description}}</span>
                <span class="vrfcd ps-2"></span>
                <span class="vrfcd">
                    <i class="bi bi-patch-check-fill"></i>
                </span>
            </div>
            <div class="fs-3 my-3  fw-bold">
                {{tokenData?.metadata_json?.name }}
            </div>
            <div class="d-flex">
                <div>
                    <span class="">Owned by</span><span class="fc-primary ps-2">{{tokenData?.owner|getAddress}}</span>
                </div>
                <!-- <div class="ms-3">
                    <span class=""><i class="bi bi-eye-fill"></i></span>
                    <span class=" ps-2">84 view</span>
                </div>
                <div class="ms-3">
                    <span class=""><i class="bi bi-heart-fill"></i></span>
                    <span class=" ps-2">2 favorite</span>
                </div> -->
            </div>
            <div class="mt-3">
                <div class="small">Current price</div>
                <div class="d-flex align-items-center">
                    <div class="icon ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-coin" viewBox="0 0 16 16">
                            <path
                                d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                        </svg>
                    </div>
                    <div class="fs-3 fw-bold px-3">{{tokenData?.price| web3Utils:'fromWei'}}</div> BNB
                    <!-- <div class="small">($00)</div> -->
                </div>
            </div>
            <div class="d-flex mt-3">
                <button *ngIf="!tokenData?.isOwner" class="btn-one" (click)="buyNow()">
                    <i class="bi bi-wallet2"></i>Buy Now
                </button>
                <!-- <button class="btn-two ms-3"><i class="bi bi-tag-fill"></i> Make Offert</button> -->
                <button *ngIf="tokenData?.isOwner" class="btn-one" (click)="Sell()">
                    <i class="bi bi-wallet2"></i>
                    Sell
                </button>

                <button *ngIf="tokenData?.isOwner" class="btn-one" (click)="Remove()">
                    <i class="bi bi-wallet2"></i>
                    Remove
                </button>
            </div>
        </div>
    </div>
</div>

<app-sell></app-sell>


<app-footer></app-footer>