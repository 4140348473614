import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-factory-stake-nft',
  templateUrl: './factory-stake-nft.component.html',
  styleUrls: ['./factory-stake-nft.component.css']
})
export class FactoryStakeNftComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
