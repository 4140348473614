<div class="card">
    <h5 class="card-header">
        Revocar permisos
    </h5>
    <div class="card-body">
        <h5 class="card-title">
            Renunciar a rol de administrador
        </h5>

        <div class="d-grid gap-2 col-6 mx-auto">
            <button type="button" class="btn btn-primary" (click)="onSubmit()">
                Renunciar
            </button>
        </div>

    </div>
</div>