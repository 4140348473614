<div class="modal fade" id="modalStoreStake" tabindex="-1" aria-labelledby="modalStoreStakeLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-header text-dark">
                <h3 class="text-center">Añadir nuevo stake token</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeModalBtn></button>
            </div>

            <div class="modal-body">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
        
                    <div class="input-group mb-3">
                        <span class="input-group-text">nameToken</span>
                        <input type="text" class="form-control" formControlName="nameToken" placeholder="Name Token">
                    </div>
        
        
                    <div class="input-group mb-3">
                        <span class="input-group-text">addressToken</span>
                        <input type="text" class="form-control" formControlName="addressToken" placeholder="Name Token">
                    </div>
        
        
                    <div class="input-group mb-3">
                        <span class="input-group-text">rewardRate</span>
                        <input type="text" class="form-control" formControlName="rewardRate" placeholder="Name Token">
                    </div>
        
        
                    <div class="input-group mb-3">
                        <span class="input-group-text">rewardPerMonth</span>
                        <input type="text" class="form-control" formControlName="rewardPerMonth" placeholder="Name Token">
                    </div>
        
        
                    <div class="input-group mb-3">
                        <span class="input-group-text">dayStaking</span>
                        <input type="text" class="form-control" formControlName="dayStaking" placeholder="Name Token">
                    </div>
        
        
                    <div class="input-group mb-3">
                        <span class="input-group-text">minStaked</span>
                        <input type="text" class="form-control" formControlName="minStaked" placeholder="Name Token">
                    </div>
        
        
                    <div class="input-group mb-3">
                        <span class="input-group-text">active</span>
                        <select class="form-select" formControlName="active">
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                    </div>

                    <div class="mb-3">
                        <div id="emailHelp" class="form-text">Unicamente Administrador</div>

                        <button class="btn btn-sm btn-primary" type="submit" [disabled]="!this.form.valid">
                            Store
                        </button>
                        <br>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- <div class="card mb-4 box-shadow text-center bg-dark">
    <div class="card-body bg-dark">
        <h3>Añadir nuevo stake token</h3>

        <form [formGroup]="form" (ngSubmit)="registerStakeToken()">

            <div class="input-group mb-3">
                <span class="input-group-text">nameToken</span>
                <input type="text" class="form-control" formControlName="nameToken" placeholder="Name Token">
            </div>


            <div class="input-group mb-3">
                <span class="input-group-text">addressToken</span>
                <input type="text" class="form-control" formControlName="addressToken" placeholder="Name Token">
            </div>


            <div class="input-group mb-3">
                <span class="input-group-text">rewardRate</span>
                <input type="text" class="form-control" formControlName="rewardRate" placeholder="Name Token">
            </div>


            <div class="input-group mb-3">
                <span class="input-group-text">rewardPerMonth</span>
                <input type="text" class="form-control" formControlName="rewardPerMonth" placeholder="Name Token">
            </div>


            <div class="input-group mb-3">
                <span class="input-group-text">dayStaking</span>
                <input type="text" class="form-control" formControlName="dayStaking" placeholder="Name Token">
            </div>


            <div class="input-group mb-3">
                <span class="input-group-text">minStaked</span>
                <input type="text" class="form-control" formControlName="minStaked" placeholder="Name Token">
            </div>


            <div class="input-group mb-3">
                <span class="input-group-text">active</span>
                <select class="form-select" formControlName="active">
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
            </div>

            <div class="mt-2 d-flex justify-content-between align-items-center">
                <div class="btn-group">
                    <a type="button" class="nav-item nav-link" [ngClass]="{'disabled': form.invalid}"
                        (click)="registerStakeToken()">Guardar</a>
                </div>
                <small class="text-muted">Unicamente Administrador</small>
            </div>
        </form>
    </div>
</div> -->