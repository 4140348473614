
<div class="modal fade" id="modalUpdatePair" tabindex="-1" aria-labelledby="modalUpdatePairLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-header text-dark">
                <h3 class="text-center">Update Pair</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeUpdateModalBtn></button>
            </div>

            <div class="modal-body">
                <form [formGroup]="form">
                    <div class="input-group mb-3">
                        <span class="input-group-text">Price</span>
                        <input type="text" class="form-control" formControlName="price" placeholder="Price" autocomplete="off">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.price.errors"
                            (click)="updatePair('price')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Token A</span>
                        <input type="text" class="form-control" formControlName="tokenA" placeholder="Token A Address"
                            autocomplete="off">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.tokenA.errors"
                            (click)="updatePair('tokenA')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Decimal Token A</span>
                        <input type="number" class="form-control" formControlName="decimalTokenA" placeholder="Decimal Token A"
                            autocomplete="off">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.decimalTokenA.errors"
                            (click)="updatePair('decimalTokenA')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Token B</span>
                        <input type="text" class="form-control" formControlName="tokenB" placeholder="Token B Address"
                            autocomplete="off">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.tokenB.errors"
                            (click)="updatePair('tokenB')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Decimal Token B</span>
                        <input type="number" class="form-control" formControlName="decimalTokenB" placeholder="Decimal Token B"
                            autocomplete="off">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.decimalTokenB.errors"
                            (click)="updatePair('decimalTokenB')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Amount for token</span>
                        <input type="number" class="form-control" formControlName="amountForTokens" placeholder="Amount for tokens"
                            autocomplete="off">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.amountForTokens.errors"
                            (click)="updatePair('amountForTokens')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Fee</span>
                        <input type="number" class="form-control" formControlName="fee" placeholder="Fee" autocomplete="off">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.fee.errors"
                            (click)="updatePair('fee')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Active Oracle</span>
                        <select class="form-select" formControlName="activeOracle" placeholder="activeOracle">
                            <option value="false">No</option>
                            <option value="true" selected>Yes</option>
                        </select>
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.activeOracle.errors"
                            (click)="updatePair('activeOracle')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Address Oracle</span>
                        <input type="text" class="form-control" formControlName="addressOracle" placeholder="addressOracle">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.addressOracle.errors"
                            (click)="updatePair('addressOracle')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Oracle Decimal</span>
                        <input type="text" class="form-control" formControlName="addressDecimalOracle"
                            placeholder="addressDecimalOracle" mask="separator.0" thousandSeparator=".">
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.addressDecimalOracle.errors"
                            (click)="updatePair('addressDecimalOracle')">Update</button>
                    </div>
                
                    <div class="input-group mb-3">
                        <span class="input-group-text">Is Native</span>
                        <select class="form-select" formControlName="isNative" placeholder="isNative">
                            <option value="false" selected>No</option>
                            <option value="true">Yes</option>
                        </select>
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.isNative.errors"
                            (click)="updatePair('isNative')">Update</button>
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text">Status</span>
                        <select class="form-select" formControlName="active" placeholder="Pair Status">
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                        <button class="btn btn-outline-secondary" type="button" [disabled]="f.isNative.errors"
                            (click)="updatePair('active')">Update</button>
                    </div>
                
                </form>
            </div>
        </div>
    </div>
</div>



