<app-nav-bar></app-nav-bar>

<div class="container container-custom mt-5">
    <div class="row mb-5">
        <div class="col">
            <div class="fs-3 fw-bold fc-light text-center">Explore Colecctions</div>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-center">
            <ul class="nav nav-secdry">
                <li class="nav-item">
                    <a class="nav-link fc-light active" aria-current="page" href="#">Trending</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fc-light" href="#">Top</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fc-light" href="#">Colection 1</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fc-light" href="#">Colection 2</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fc-light" href="#">Colection 3</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mt-5">
        <div *ngFor="let item of listCard" class="col-md-6 col-lg-4 col-xl-3 pt-5"
            [routerLink]="['/pages/collection', item.smartContract, item.index]">
            <div class="card card-custom card-collection">
                <div class="card-header">
                    <img [src]="item.image1" alt="">
                </div>
                <div class="card-body">
                    <div class="avtr">
                        <img [src]="item.image2" alt="">
                    </div>
                    <div class="text-center mt-3">
                        <div class="fw-bold small fc-light">
                            {{item.title}}
                        </div>
                        <div class="small">
                            by <span class="fc-primary"> {{item.by}}.</span>
                        </div>
                    </div>
                    <div class="fc-light mt-3 text-center">
                        {{item.description}}
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<app-footer></app-footer>