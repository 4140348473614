<div class="container mt-3">

    <div class="row">



        <!-- Contract -->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        Contract
                    </h5>
                    <p class="card-text">
                        Manager Contract Settings
                    </p>
                    <a [routerLink]="['/admin/contract']" class="btn btn-primary">Go</a>
                </div>
            </div>
        </div>


        <!-- Security -->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        Secutiry
                    </h5>
                    <p class="card-text">
                        Manager Secutiry
                    </p>
                    <a [routerLink]="['/admin/security']" class="btn btn-primary">Go</a>
                </div>
            </div>
        </div>


        <!-- Factory Swap -->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        Factory Swap
                    </h5>
                    <p class="card-text">
                        Manager Factory Swap
                    </p>
                    <a [routerLink]="['/admin/factory-swap']" class="btn btn-primary">Go</a>
                </div>
            </div>
        </div>


        <!-- Factory Pool -->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        Factory Pool
                    </h5>
                    <p class="card-text">
                        Manager Factory Pool
                    </p>
                    <a [routerLink]="['/admin/factory-pool']" class="btn btn-primary">Go</a>
                </div>
            </div>
        </div>


        <!-- Factory Stake Token -->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        Factory Stake Token
                    </h5>
                    <p class="card-text">
                        Manager Factory Stake Token
                    </p>
                    <a [routerLink]="['/admin/factory-stake-token']" class="btn btn-primary">Go</a>
                </div>
            </div>
        </div>


        <!-- Factory Stake NFT -->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        Factory Stake NFT
                    </h5>
                    <p class="card-text">
                        Manager Factory Stake NFT
                    </p>
                    <a [routerLink]="['/admin/factory-stake-nft']" class="btn btn-primary">Go</a>
                </div>
            </div>
        </div>

    </div>
</div>