import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-factory-swap',
  templateUrl: './factory-swap.component.html',
  styleUrls: ['./factory-swap.component.css']
})
export class FactorySwapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
