<footer class="pt-4 pb-4">
  <div class="container">
    <div class="row align-items-end">
      <div class="col-12 col-lg-4">
        <p class="text-center">2022 DECENTRA</p>
      </div>
      <div class="col-12 col-lg-4">
        <div class="text-center">
          <img src="assets/img/logo-footer.png" class="img-fluid logo" alt="" />
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <p class="text-center">made by DECENTRA MARKETING</p>
      </div>
    </div>
  </div>
</footer>
