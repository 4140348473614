<div>
  <div class="bg-home">
    <app-nav-bar></app-nav-bar>
    <div class="container container-home pb-5 mt-5">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 class="text-center mb-4 text-degradient title-principal mb-5">
            Tu primera Tierra dentro <br />
            del Metaverso
          </h1>
        </div>
      </div>
      <div class="row align-items-center">
        <div
          class="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center flex-column"
        >
          <div class="card">
            <form
              [formGroup]="form"
              class="form d-flex flex-column align-items-center"
              (ngSubmit)="onSubmit()"
              novalidate
            >
              <p class="text-center">¿Cuantos lotes deseas <br />comprar?</p>
              <div
                class="number-input d-flex justify-content-center align-items-center mb-4"
              >
                <div class="d-flex text-center input-value">
                  <button
                    class="btn-camp-input"
                    type="button"
                    (click)="handlerQuantity('sub')"
                    [disabled]="f.quantity.value == 1"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    class="form-control fs-4 mx-2 wp"
                    placeholder="1"
                    aria-label="1"
                    aria-describedby="basic-addon2"
                    formControlName="quantity"
                  />
                  <button
                    class="btn-camp-input"
                    type="button"
                    (click)="handlerQuantity('add')"
                    [disabled]="f.quantity.value === maxBuyLimit"
                  >
                    +
                  </button>
                </div>
              </div>
              <div
                class="mb-4 d-flex justify-content-center align-items-center flex-column w-100"
              >
                <p>Token de pago</p>
                <p class="fs-3">
                  <ng-container *ngIf="vendorList; else noCollecctionPrice">
                    {{ vendorList.priceParsed * f.quantity.value | number }} $
                  </ng-container>

                  <ng-template #noCollecctionPrice> ---- $ </ng-template>
                </p>

                <ng-container *ngIf="dataStatus; else noTokens">
                  <div class="dropdown w-100">
                    <button
                      class="select-form form-select dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <ng-container *ngIf="!tokenToPay; else showOpt">
                        <img src="assets/img/icon-binance.png" alt="" /> Elegir
                      </ng-container>

                      <ng-template #showOpt>
                        <img
                          src="assets/img/{{ tokenToPay.tokenAddress }}.png"
                          style="width: 21px"
                          alt=""
                        />

                        <ng-container *ngIf="!tokenToPay.isNative">
                          {{
                            tokenToPay.tokenAddress | erc20: "symbol" | async
                          }}
                        </ng-container>

                        <ng-container *ngIf="tokenToPay.isNative">
                          {{ nativeCurrency.symbol }}
                        </ng-container>
                      </ng-template>
                    </button>

                    <!-- <button class="select-form form-select dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="assets/img/icon-binance.png" alt="" /> BUSD
                    </button> -->
                    <ul
                      class="dropdown-menu w-100"
                      aria-labelledby="dropdownMenuButton1"
                      *ngIf="whiteList"
                    >
                      <li
                        class="text-center"
                        *ngFor="let item of whiteList"
                        (click)="selectToken(item)"
                      >
                        <img [src]="item.image" style="width: 21px" alt="" />

                        <ng-container *ngIf="!item.isNative; else showNative">
                          {{ item.tokenAddress | erc20: "symbol" | async }}
                        </ng-container>

                        <ng-template #showNative>
                          {{ nativeCurrency.symbol }}
                        </ng-template>
                      </li>
                    </ul>
                  </div>
                </ng-container>

                <ng-template #noTokens>
                  <div class="dropdown w-100">
                    <button
                      class="select-form form-select dropdown-toggle disabled"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="assets/img/icon-binance.png" alt="" /> ...
                    </button>
                  </div>
                </ng-template>
              </div>

              <div class="mb-4 mt-3 w-100 text-center">
                <ng-container *ngIf="dataStatus; else connectButton">
                  <ng-container *ngIf="tokenToPay; else pairRequired">
                    <button type="submit" class="button w-100">Comprar</button>
                  </ng-container>
                </ng-container>

                <ng-template #pairRequired>
                  <button type="button" class="btn btn-custom" disabled>
                    Conectar
                  </button>
                </ng-template>

                <ng-template #connectButton>
                  <button
                    type="button"
                    class="btn btn-custom"
                    (click)="contractSrv.connectAccount()"
                  >
                    Conectar
                  </button>
                </ng-template>
              </div>
            </form>
          </div>
        </div>
        <div
          class="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="container-panel">
            <img
              src="assets/img/panel.png"
              class="img-fluid panel-one"
              alt=""
            />
            <img
              src="assets/img/panel-two.png"
              class="img-fluid panel-two"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="row section-buy-step">
        <div class="col-12 col-lg-6">
          <h3 class="text-degradient title-buy">
            ¿Cómo <br />
            Comprar?
          </h3>
          <div class="row mt-4">
            <div class="col-12 col-lg-6">
              <div class="buy-step-item">
                <p class="text-number">01</p>
                <p class="text-step">
                  Para poder comprar un terreno solo requieres tener wallet que
                  cuente con la red de <b>Binance-smart chain.</b>
                </p>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="buy-step-item">
                <p class="text-number">02</p>
                <p class="text-step">
                  Debes agrregar <b>BNB</b> (la moneda nativa de Binance), ya
                  que con esta pagaras la comisión de la red.
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="buy-step-item">
                <p class="text-number">03</p>
                <p class="text-step">
                  Podrás hacer el pago con <b>BNB, BUSD, Y USDT.</b>
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="buy-step-item">
                <p class="text-number">04</p>
                <p class="text-step">
                  Cada parcela tiene un valor de 125 dólares y se te brindará un
                  <b>terreno de 10x10.</b>
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-5">
            <button class="btn btn-custom">Comprar Aquí</button>
          </div>
        </div>
        <div class="col-12 col-lg-5 d-none d-md-block">
          <img
            src="assets/img/edificio.jpg"
            class="img-fluid img-edificio"
            alt=""
          />
        </div>

        <div c></div>
      </div>
    </div>
  </div>

  <div class="section-comparative">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="text-center title-principal mb-5">
            Comparativa <br class="d-md-none" />
            de otros <br class="d-none d-md-block" />
            METAVERSOS
          </h2>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="pb-15 color-blue">Metaverso</th>
                  <th scope="col">
                    Precio actual de <br class="d-none d-md-block" />
                    una tierra
                  </th>
                  <th scope="col">
                    Precio Máximo de <br class="d-none d-md-block" />
                    venta de una tierra
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" class="color-blue">Decentra City</th>
                  <td>125 dólares</td>
                  <td>-</td>
                </tr>
                <tr>
                  <th scope="row" class="color-blue">SandBox</th>
                  <td>4,773 dólares</td>
                  <td>4.3 millones de dólares</td>
                </tr>
                <tr>
                  <th scope="row" class="color-blue">Decentraland</th>
                  <td>2,485.84</td>
                  <td>3.5 millones de dólares</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-faq">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-5">
          <img
            src="assets/img/faq-image.png"
            class="img-fluid mb-5 mb-md-0"
            alt=""
          />
        </div>

        <div class="col-12 col-md-7">
          <div class="d-flex align-items-center justify-content-center">
            <h4>Decity</h4>
            <p>NFT</p>
          </div>
          <p class="text-center">
            Ya puedes comprar tu NFT de <b>DECITY.</b> Este te otorga la primera
            tierra dentro de <b>DECITY</b> a precio de preventa. Y te da el
            beneficio de ser de los primeros en comprar tierras antes que nadie
            en el lanzamiento publico de la venta de tierras.
          </p>
        </div>

        <div class="col-12 col-md-10 offset-md-2 bg-circle z2">
          <h3 class="text-degradient title-faq text-center mb-4">FAQ</h3>

          <div class="faq-item">
            <div class="faq-open-close" (click)="openTab($event, 'one')">
              <img
                src="assets/img/plus.png"
                class="img-fluid plus active"
                alt=""
              />
              <img src="assets/img/minus.png" class="minus img-fluid" alt="" />
            </div>
            <h4 class="faq-item-title">
              ¿Qué dicen los expertos del Metaverso?
            </h4>
            <p id="one">
              La directora de inversión sénior de renta variable asiática de
              Abrdn, <b>Pruska Iamthongthong</b>, ha destacado recientemente que
              el metaverso "podría acabar siendo un avance importantte como lo
              fue el internet en los a años 90", en tanto que otros lo han
              calificado como "La ​evolución natural de internet".
            </p>
          </div>

          <div class="faq-item">
            <div class="faq-open-close" (click)="openTab($event, 'two')">
              <img
                src="assets/img/plus.png"
                class="img-fluid plus active"
                alt=""
              />
              <img src="assets/img/minus.png" class="minus img-fluid" alt="" />
            </div>
            <h4 class="faq-item-title">¿Qué puedo hacer en el Metaverso?</h4>
            <p id="two">
              En el Metaverso, podrás hacer casi cualquier cosa que puedas
              imaginar: reunirse con amigos y familiares, trabajar, aprender,
              jugar, comprar, crear; así como experiencias completamente nuevas
              que realmente no se ajustan a nuestra forma de pensar sobre
              computadoras o teléfonos hoy.
              <br />
              Mark Zuckerberg.
            </p>
          </div>
          <div class="faq-item">
            <div class="faq-open-close" (click)="openTab($event, 'three')">
              <img
                src="assets/img/plus.png"
                class="img-fluid plus active"
                alt=""
              />
              <img src="assets/img/minus.png" class="minus img-fluid" alt="" />
            </div>
            <h4 class="faq-item-title">
              ¿Cuál es la proyección del Metaverso?
            </h4>
            <p id="three">
              Según estimaciones de Bloomberg, se espera que este mercado supere
              los US% 810.000 millones para 2028.
            </p>
          </div>
          <div class="faq-item">
            <div class="faq-open-close" (click)="openTab($event, 'four')">
              <img
                src="assets/img/plus.png"
                class="img-fluid plus active"
                alt=""
              />
              <img src="assets/img/minus.png" class="minus img-fluid" alt="" />
            </div>
            <h4 class="faq-item-title">
              ¿Qué oportunidades brinda el Metaverso?
            </h4>
            <p id="four">
              Según un informe de Grayscale, el sector podría presentar una
              oportunidad de ingresos hasta un billón de dólares (877.443
              millones de euros).
            </p>
          </div>
          <img
            src="assets/img/person-img.png"
            class="img-fluid person-img"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <app-footer> </app-footer>
</div>
