<app-nav-bar></app-nav-bar>

<section>
    <div class="header_p">
        <img class="banner-img" src="/assets/img/collection.jpg" alt="">
        <div class="avtr">
            <img src="/assets/img/collection.jpg" alt="">
        </div>
    </div>
</section>

<div class="container container-custom mt-5">
    <div class="row mb-5">
        <div class="col">
            <div class="fs-3 fw-bold fc-light">
                {{nameContract | uppercase}}
            </div>
            <div class="small fc-light">{{smartContract | getAddress}}
                <span>{{date| date}}</span>
            </div>
        </div>
        <div class="col d-flex justify-content-end">
            <div class="d-flex">
                <div class="ms-3 fc-light"><i class="bi bi-share-fill"></i></div>
                <div class="ms-3 fc-light"><i class="bi bi-three-dots"></i></div>
            </div>
        </div>
    </div>

    <div class="row">
        <div *ngFor="let item of listNft" class="col-md-6 col-lg-3 pt-5" (click)="goToNft(item)">
            <div class="card card-custom card-collection card-collection-prfl">
                <div class="card-header">
                    <img [src]="item.metadata_json.image" alt="">
                    <div class="fc-primary icon-fltn">
                        <i class="bi bi-play-circle-fill"></i>
                    </div>
                </div>
                <div class="card-body">
                    <div class="text-start">
                        <div class="fw-bold small fc-light">
                            {{ item.metadata_json.name}}
                        </div>
                        <div class="small">
                            by <span class="fc-primary"> {{ item.owner | getAddress}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer></app-footer>