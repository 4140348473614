<div class="card">
    <h5 class="card-header">
        Revocar permisos
    </h5>
    <div class="card-body">
        <h5 class="card-title">
            Remover rol de usuario
        </h5>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
                <input type="text" formControlName="address" class="form-control" placeholder="Address">
                <label for="floatingInput">
                    Wallet Address
                </label>
              </div>

            <div class="d-grid gap-2 col-6 mx-auto">
                <button type="submit" class="btn btn-primary">
                    Guardar
                </button>
            </div>

        </form>
    </div>
</div>