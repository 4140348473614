import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AddTokenAMetamaskService } from 'src/app/services/add-metamask.service';
import { ContractService } from 'src/app/services/contract.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  public dataStatus$!: Observable<any>;

  constructor(
    private contractSrv: ContractService,
    public addMetamask: AddTokenAMetamaskService,
  ) { }

  ngOnInit(): void {
    this.dataStatus$ = this.contractSrv.dataStatus$;
  }

  connect(){
    return this.contractSrv.connectAccount();
  }

  logout(){
    return this.contractSrv.logout();
  }

}
