<div class="menu-side d-none d-md-flex">
  <div class="d-flex justify-content-between align-items-center flex-column">
    <img src="assets/img/logo-menu.png" class="img-fluid logo-menu" alt="" />
    <img
      src="assets/img/menu-icon.png"
      width="20px"
      class="img-fluid logo"
      alt=""
    />
  </div>
</div>

<div class="container">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand">
        <!-- <img src="assets/img/logo.png" class="img-fluid" alt=""/> -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <div class="buttons">
          <ng-container
            *ngIf="dataStatus$ | async as dataStatus; else connectWallet"
          >
            <button class="button-one" (click)="addMetamask.addChainId()">
              Add Network
            </button>
            <button class="button-two" (click)="logout()">
              {{ dataStatus.accounts | truncateWalletAddress }}
            </button>
          </ng-container>

          <ng-template #connectWallet>
            <button class="button-one" (click)="connect()">Add Network</button>
            <button class="button-two" (click)="connect()">
              Wallet Connect
            </button>
          </ng-template>

          <button class="button-two" (click)="addMetamask.addChainId()">
            Ver Video
          </button>
        </div>
      </div>
    </div>
  </nav>
</div>
