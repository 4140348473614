<section class="jumbotron text-center mysection">
    <div class="container ">
        <h1 class="jumbotron-heading text-dark mytext">Factory Stake NFT</h1>
    </div>
</section>
<div class="album py-5 ">
    <div class="container">
        <div class="row">
            <!-- <div class="co-12">
                <div class="container-fluid">
                    <div class="d-flex justify-content-center">
                        <div class="row">
                            <div class="col-md-12">
                                <app-register-stake-nft></app-register-stake-nft>
                            </div>
                            <div class="col-md-6">
                                <app-register-active-nft></app-register-active-nft>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="col-12">
                <div class="card w-100 text-dark">
                    <div class="card-header">
                        <div class="hstack gap-2">
                            <h2 class="border">Factory Stake Token</h2>
        
                            <button type="button" class="ms-auto border btn btn-sm btn-outline-primary" (click)="loadStakeList()">Refresh</button>
                            <div class="vr"></div>
                            <button type="button" class="border btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#modalStoreStake">Add</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">nameAddressNft</th>
                                        <th scope="col">addressNft</th>
                                        <th scope="col">nameAddressTokenReward</th>
                                        <th scope="col">addressTokenReward</th>
                                        <th scope="col">rewardTotal</th>
                                        <th scope="col">day</th>
                                        <th scope="col">status</th>
                                        <th scope="col">Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="stakeList.length > 0; else noStakeList">
                                        <tr class="align-middle" *ngFor="let item of stakeList; let i = index">
                                            <th scope="row">{{i+1}}</th>
                                            <td>{{item.nameAddressNft}}</td>
                                            <td>{{item.addressNft}}</td>
                                            <td>{{item.nameAddressTokenReward}}</td>
                                            <td>{{item.addressTokenReward}}</td>
                                            <td>{{item.rewardTotal}}</td>
                                            <td>{{item.day}}</td>
                                            <td>{{item.status}}</td>
                                            <td>
                                                <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#modalUpdatePair" (click)="updateStake(item)">
                                                    <ng-container *ngIf="!item.active">
                                                        <i class="bi bi-eye-fill"></i>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.active">
                                                        <i class="bi bi-eye-slash-fill"></i>
                                                    </ng-container>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #noStakeList>
                                        <tr>
                                            <td colspan="9" class="text-center">
                                                No pair registered yet
                                            </td>
                                        </tr>
                                    </ng-template>
                        
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>



<app-factory-stake-nft-store></app-factory-stake-nft-store>