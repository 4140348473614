<div class="container mt-3">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/admin']">
            <i class="bi bi-arrow-left"></i> Dashboard
          </a></li>
          <li class="breadcrumb-item active" aria-current="page">Factory Swap</li>
        </ol>
    </nav>
    
    <app-list-factory-swap></app-list-factory-swap>
    
</div>