<div class="container mt-3">

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/admin']">
            <i class="bi bi-arrow-left"></i> Dashboard
          </a></li>
          <li class="breadcrumb-item active" aria-current="page">Contract</li>
        </ol>
    </nav>

    <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
            <app-buy-limit></app-buy-limit>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <app-sell-limit></app-sell-limit>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <app-pausable></app-pausable>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <app-burn></app-burn>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <app-mint></app-mint>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <app-transfer-ownership></app-transfer-ownership>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <app-set-vault-address></app-set-vault-address>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <app-withdraw-native-token></app-withdraw-native-token>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <app-withdraw-token-onwer></app-withdraw-token-onwer>
        </div>
    </div>

</div>